import { Tag } from 'antd';
import { Button } from '../button';
import { useNavigate } from 'react-router-dom';

export const SubscriptStatusLabel = () => {
  const navigator = useNavigate();
  const getSubscriptionPage = () => {
    navigator('/hulamSubscription');
  };
  return (
    <Tag
      icon={
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="4" fill="#ACB1BA" />
        </svg>
      }
      color="#F3F4F8"
      style={{
        color: 'var(--gray-600, #6D717B)',
      }}
    >
      4대보험 연동 안됨
      <Button
        type="text"
        style={{
          display: 'inline-block',
          padding: 0,
          marginLeft: 8,
          height: 'auto',
          color: 'var(--gray-600, #6D717B)',
          fontSize: 12,
          fontWeight: 500,
          lineHeight: '12px',
          textDecorationLine: 'underline',
        }}
        onClick={getSubscriptionPage}
      >
        기본 서비스 구독하고 4대보험 연동하기
      </Button>
    </Tag>
  );
};
