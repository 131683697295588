import styles from './chips-container.module.css';
import { DeleteIcon } from '../icon';
import Scrollbars from 'react-custom-scrollbars-2';

export const ChipsContainer = ({
  list,
  onDeleteItem,
  onReset,
  height,
  maxHeight = 0,
  id,
  name,
}) => {
  return (
    <div className={styles.container}>
      <Scrollbars
        className={styles.list_box}
        autoHeight
        autoHeightMin={height}
        autoHeightMax={maxHeight || 'none'}
      >
        {list.map(item => (
          <div key={`chips_${item[id]}`} className={styles.chip}>
            <span>{item[name]}</span>
            <button type={'button'} onClick={() => onDeleteItem(item[id])}>
              <DeleteIcon size={16} color={'#555555'} />
            </button>
          </div>
        ))}
      </Scrollbars>
      <button className={styles.reset_button} type="button" onClick={onReset}>
        전체삭제
      </button>
    </div>
  );
};
