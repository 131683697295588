import { useState, useEffect } from 'react';

import { Table } from 'antd';

import { Flex, MultiButton, Button } from 'components/hulam_platform';
//import axios from "axios";
import { callApi } from 'utils';

import { useAuth } from 'AuthProvider';

export const OperatorCompanyList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { author, adminlogin } = useAuth();

  const [data, setData] = useState([]);

  useEffect(() => {
    const operatorCompanyList = async () => {
      let sessionStorage = window.sessionStorage;
      let opId = sessionStorage.getItem('userId');

      const postData = {
        opId: opId,
        author: author,
      };
      setIsLoading(true);
      const response = await callApi('api/admin/operatorCompanyList', postData);

      if (response && response.data) {
        let data = response.data.map((item, index) => ({ ...item, key: `${item.name}_${index}` }));
        setData(data);

        setIsLoading(false);
      } else {
        setData([]);
        setIsLoading(false);
      }
    };
    if (author === 'admin') {
      operatorCompanyList();
    }
  }, [author]);

  const columns = [
    {
      title: '순번',
      fixed: true,
      dataIndex: 'index',
      key: 'index',
      zIndex: 1200,
      width: 110,
      render: (text, record, index) => {
        console.log(text, record, index);
        return index + 1;
      },
    },
    {
      title: '대행사명',
      fixed: true,
      dataIndex: 'mbName',
      key: 'mbName',
      zIndex: 1200,
      width: 210,
      sorter: (a, b) => a.companyName.localeCompare(b.companyName, 'ko'),
    },
    {
      title: '대행사 아이디',
      dataIndex: 'mbId',
      key: 'mbId',
      width: 254,
    },
    {
      title: '가입 일자',
      dataIndex: 'mbDatetime',
      key: 'mbDatetime',
      width: 254,
    },
    {
      title: '작업',
      dataIndex: 'work',
      key: 'work',
      render: (text, record) => {
        return (
          <MultiButton>
            <Button
              type="line-purple"
              size="sm"
              onClick={async () => {
                if (record.mbId) {
                  let sessionStorage = window.sessionStorage;
                  let adminId = sessionStorage.getItem('userId');
                  let adminAccessToken = sessionStorage.getItem('accessToken');

                  let response = {
                    type: 'manager',
                    companyName: record.mbName,
                    userId: record.mbId,
                    access_token: record.accessToken,
                    adminId,
                    adminAccessToken,
                  };
                  let opId = sessionStorage.getItem('userId');

                  const postData = {
                    opId: opId,
                    userId: record.mbId,
                    // 추가 필드
                  };

                  await callApi('api/admin/loginInfo', postData);
                  adminlogin(response);
                }
              }}
            >
              {' '}
              해당 대행사로 로그인하기
            </Button>
          </MultiButton>
        );
      },
    },
  ];

  return (
    <div>
      <Flex justifyContent="space-between">
        <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>대행사 목록</h2>
      </Flex>
      <div>
        <Table
          columns={columns}
          dataSource={data}
          style={{ marginTop: 16 }}
          scroll={{
            x: 1500,
          }}
          pagination={{
            pageSize: 100,
          }}
          loading={isLoading}
        />
        <div style={{ height: 100 }}></div>
      </div>
    </div>
  );
};
