import { Modal } from 'antd';
import { DeleteIcon } from '../icon';
import { Button } from '../button';
import React from 'react';

const YouTubeVideo = ({ videoId }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}?cc_load_policy=1`;

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
        borderRadius: 6,
      }}
    >
      <iframe
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        title="YouTube Video"
      />
    </div>
  );
};

export const OnBoardingVideoModal = ({ isOpen, onClosed, title, videoId }) => {
  return (
    <Modal
      title={title}
      open={isOpen}
      centered
      closeIcon={<DeleteIcon />}
      onCancel={onClosed}
      footer={
        <Button type="line-gray" size={'sm'} onClick={onClosed}>
          닫기
        </Button>
      }
      width={780}
      style={{
        body: {
          width: 600,
          minHeight: 600,
          height: 600,
        },
      }}
    >
      <YouTubeVideo videoId={videoId} />
    </Modal>
  );
};
