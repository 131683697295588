import React from 'react';
import { Button, Flex, SelectBox } from 'components/hulam_platform';
import styles from './ProfileEdit.module.css';
import { callApi, extractNumbers, snackBar } from 'utils';
import { useAuth } from 'AuthProvider';
import { Col, Form, Input, Row } from 'antd';
import { useQuery } from 'react-query';

const businessConfig = {
  rules: [
    {
      required: true,
      message: '사업자 등록 번호를 입력해 주세요.',
    },
  ],
};

const phoneConfig = {
  rules: [
    {
      required: true,
      message: '휴대폰 번호를 입력해 주세요.',
    },
  ],
};

const emailConfig = {
  rules: [
    {
      required: true,
      message: '이메일을 입력해 주세요.',
    },
  ],
};

const formItemLayout = {
  labelCol: {
    span: 3,
  },
  colon: false,
  labelAlign: 'left',
};

const profileFields = [
  {
    name: 'companyName',
    value: '',
  },
  {
    name: 'presidentName',
    value: '',
  },
  {
    name: 'businessNumber1',
    value: '',
  },
  {
    name: 'businessNumber2',
    value: '',
  },
  {
    name: 'businessNumber3',
    value: '',
  },
  {
    name: 'phoneNumber1',
    value: '',
  },
  {
    name: 'phoneNumber2',
    value: '',
  },
  {
    name: 'phoneNumber3',
    value: '',
  },
  {
    name: 'email',
    value: '',
  },
  {
    name: 'email1',
    value: '',
  },
];

const employeeFields = [
  {
    name: 'employeeName',
    value: '',
  },
  {
    name: 'role',
    value: '',
  },
  {
    name: 'email',
    value: '',
  },
  {
    name: 'email1',
    value: '',
  },
  {
    name: 'phoneNumber1',
    value: '',
  },
  {
    name: 'phoneNumber2',
    value: '',
  },
  {
    name: 'phoneNumber3',
    value: '',
  },
];

export default function ProfileEditTab(props) {
  const opId = sessionStorage.getItem('userId');
  const opeNo = sessionStorage.getItem('opeNo');
  const email = sessionStorage.getItem('email');

  const { author, setCompanyName } = useAuth();
  const [profileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [employeeForm] = Form.useForm();

  const profileSetting = async () => {
    if (opId) {
      const postData = {
        opId: opId,
      };

      const response = await callApi('api/platform/viewPrivateSetting', postData);

      if (response && response.result) {
        const { data } = response;
        if (data) {
          const newData = profileFields.map(item => {
            // 사업자 등록번호 스플릿
            if (data.businessNumber) {
              let businessNumber = data.businessNumber.replaceAll('-', '');
              if (item.name === 'businessNumber1') {
                return { ...item, value: businessNumber?.substr(0, 3) };
              }

              if (item.name === 'businessNumber2') {
                return { ...item, value: businessNumber?.substr(3, 2) };
              }

              if (item.name === 'businessNumber3') {
                return { ...item, value: businessNumber?.substr(5, 5) };
              }
            }

            // 휴대폰 번호 스플릿
            if (data.phoneNumber) {
              let phoneNumber = data.phoneNumber.replaceAll('-', '');
              if (item.name === 'phoneNumber1') {
                return { ...item, value: phoneNumber?.substr(0, 3) };
              }

              if (item.name === 'phoneNumber2') {
                return { ...item, value: phoneNumber?.substr(3, 4) };
              }

              if (item.name === 'phoneNumber3') {
                return { ...item, value: phoneNumber?.substr(7, 4) };
              }
            }

            // 이메일 스플릿
            if (data.emailAddress) {
              if (item.name === 'email') {
                return { ...item, value: data.emailAddress?.split('@')[0] };
              }

              if (item.name === 'email1') {
                return { ...item, value: data.emailAddress?.split('@')[1] };
              }
            }

            if (data[item.name]) {
              return { ...item, value: data[item.name] };
            } else {
              return item;
            }
          });

          return newData;
        }
      }
    }
  };

  const employeeSetting = async () => {
    if (opId) {
      const postData = {
        opId: opId,
        opeNo: opeNo,
        email: email,
      };

      const response = await callApi('api/platform/viewPrivateSetting', postData);

      if (response && response.result) {
        const { data } = response;

        if (data) {
          const newData = employeeFields.map(item => {
            // 휴대폰 번호 스플릿
            if (data.phoneNumber) {
              let phoneNumber = data.phoneNumber.replaceAll('-', '');
              if (item.name === 'phoneNumber1') {
                return { ...item, value: phoneNumber?.substr(0, 3) };
              }

              if (item.name === 'phoneNumber2') {
                return { ...item, value: phoneNumber?.substr(3, 4) };
              }

              if (item.name === 'phoneNumber3') {
                return { ...item, value: phoneNumber?.substr(7, 4) };
              }
            }

            // 이메일  스플릿
            if (data.emailAddress) {
              if (item.name === 'email') {
                return { ...item, value: data.emailAddress?.split('@')[0] };
              }

              if (item.name === 'email1') {
                return { ...item, value: data.emailAddress?.split('@')[1] };
              }
            }

            if (data[item.name]) {
              return { ...item, value: data[item.name] };
            } else {
              return item;
            }
          });

          return newData;
        }
      }
    }
  };

  const { data } = useQuery(`profileInfo`, author === 'manager' ? profileSetting : employeeSetting);

  const updateProfile = async fieldsValue => {
    const postData = {
      opId: opId,
      companyName: fieldsValue['companyName'],
      presidentName: fieldsValue['presidentName'],
      businessNumber: `${fieldsValue['businessNumber1']}-${fieldsValue['businessNumber2']}-${fieldsValue['businessNumber3']}`,
      phoneNumber: `${fieldsValue['phoneNumber1']}-${fieldsValue['phoneNumber2']}-${fieldsValue['phoneNumber3']}`,
      emailAddress: `${fieldsValue['email']}@${fieldsValue['email1']}`,
    };

    const response = await callApi('api/platform/updatePrivateSetting', postData);

    if (response && response.result) {
      snackBar('프로필 정보가 수정되었습니다.');
      setCompanyName(fieldsValue['companyName']);
    }
  };

  const updateEmployee = async fieldsValue => {
    const postData = {
      opId: opId,
      opeNo: opeNo,
      email: email,
      employeeName: fieldsValue['employeeName'],
      role: fieldsValue['role'],
      phoneNumber: `${fieldsValue['phoneNumber1']}-${fieldsValue['phoneNumber2']}-${fieldsValue['phoneNumber3']}`,
    };

    const response = await callApi('api/platform/updatePrivateSetting', postData);

    if (response && response.result) {
      snackBar('프로필 정보가 수정되었습니다.');
    }
  };

  const updatePassword = async fieldsValue => {
    if (author === 'manager') {
      const postData = {
        opId: opId,
        origin: fieldsValue['existingPassword'],
        password: fieldsValue['passwordConfirm'],
      };

      const response = await callApi('api/platform/updatePasswordSetting', postData);

      if (response && response.result) {
        snackBar('패스워드가 정상 변경되었습니다.');
        passwordForm.resetFields();
      } else {
        snackBar('기존 패스워드 정보가 맞지 않습니다.');
      }
    } else {
      const postData = {
        opId: opId,
        opeNo: opeNo,
        email: email,
        origin: fieldsValue['existingPassword'],
        password: fieldsValue['passwordConfirm'],
      };

      const response = await callApi('api/platform/updatePasswordSetting', postData);

      if (response && response.result) {
        snackBar('패스워드가 정상 변경되었습니다.');
        passwordForm.resetFields();
      } else {
        snackBar('기존 패스워드 정보가 맞지 않습니다.');
      }
    }
  };

  return (
    <div>
      <ul className={styles.profile_container}>
        {author === 'manager' ? (
          <div>
            <div className={styles.container}>
              <Form form={profileForm} fields={data} onFinish={updateProfile} {...formItemLayout}>
                <Flex justifyContent="space-between">
                  <h2>계정 정보</h2>
                  <Button width={124} onClick={() => profileForm.submit()}>
                    수정하기
                  </Button>
                </Flex>
                <Form.Item
                  label="회사명"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="입력" style={{ width: 515 }} />
                </Form.Item>
                <Form.Item
                  label="대표자명"
                  name="presidentName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="입력" style={{ width: 515 }} />
                </Form.Item>
                <Form.Item label="사업자등록번호" required>
                  <Flex columnGap={10}>
                    <Form.Item
                      name="businessNumber1"
                      noStyle
                      {...businessConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={3}
                        controls={false}
                      />
                    </Form.Item>
                    -
                    <Form.Item
                      name="businessNumber2"
                      noStyle
                      {...businessConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={2}
                        controls={false}
                      />
                    </Form.Item>
                    -
                    <Form.Item
                      name="businessNumber3"
                      noStyle
                      {...businessConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={5}
                        controls={false}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
                <Form.Item label="휴대폰 번호" required>
                  <Flex columnGap={10}>
                    <Form.Item
                      name="phoneNumber1"
                      noStyle
                      {...phoneConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={3}
                        controls={false}
                      />
                    </Form.Item>
                    -
                    <Form.Item
                      name="phoneNumber2"
                      noStyle
                      {...phoneConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={4}
                        controls={false}
                      />
                    </Form.Item>
                    -
                    <Form.Item
                      name="phoneNumber3"
                      noStyle
                      {...phoneConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={4}
                        controls={false}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
                <Form.Item label="이메일" required>
                  <Flex>
                    <Form.Item name="email" noStyle {...emailConfig}>
                      <Input placeholder="입력" style={{ width: 180 }} />
                    </Form.Item>
                    @
                    <Form.Item name="email1" noStyle {...emailConfig}>
                      <Input placeholder="입력" style={{ width: 180 }} />
                    </Form.Item>
                    <SelectBox
                      value={''}
                      style={{ width: '118px' }}
                      suffixIcon={<i className="svg_icon_16_search_arrow_down"></i>}
                      options={[
                        {
                          value: '',
                          label: '직접입력',
                        },
                      ]}
                    />
                  </Flex>
                </Form.Item>
              </Form>
            </div>
            <div className={styles.container}>
              <Form form={passwordForm} {...formItemLayout} onFinish={updatePassword}>
                <Flex justifyContent="space-between">
                  <h2>비밀번호 변경</h2>
                  <Button width={124} onClick={() => passwordForm.submit()}>
                    수정하기
                  </Button>
                </Flex>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="기존 비밀번호"
                      name="existingPassword"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password placeholder="입력" style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="비밀번호"
                      name="password"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password placeholder="입력" style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  justify="space-between"
                  style={{ width: 300, marginTop: -20, marginBottom: 13, marginLeft: 160 }}
                >
                  <Col span={24}>
                    영문, 숫자, 특수문자 3종류 이상을 조합하여 최소 8자리 이상의 길이를
                    입력해주세요.
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="비밀번호 확인"
                      name="passwordConfirm"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const pattern =
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

                            if (!pattern.test(value)) {
                              return Promise.reject(new Error('비밀번호 규칙이 맞지 않습니다.'));
                            }
                            if (!pattern.test(getFieldValue('password'))) {
                              return Promise.reject(new Error('비밀번호 규칙이 맞지 않습니다.'));
                            }

                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('패스워드 정보가 맞지 않습니다.'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="입력" style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.container}>
              <Form form={employeeForm} fields={data} onFinish={updateEmployee} {...formItemLayout}>
                <Flex justifyContent="space-between">
                  <h2>계정 정보</h2>
                  <Button width={124} onClick={() => employeeForm.submit()}>
                    수정하기
                  </Button>
                </Flex>
                <Form.Item
                  label="이름"
                  name="employeeName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="입력" style={{ width: 515 }} />
                </Form.Item>
                <Form.Item
                  label="담당"
                  name="role"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="입력" style={{ width: 515 }} />
                </Form.Item>
                <Form.Item
                  label="이메일 주소"
                  extra={
                    <span className="error_message">
                      수정은 업무 대행사 계정 담당자에게 요청해주세요
                    </span>
                  }
                  required
                >
                  <Flex>
                    <Form.Item name="email" noStyle>
                      <Input placeholder="입력" disabled style={{ width: 180 }} />
                    </Form.Item>
                    <span style={{ alignSelf: 'center' }}>@</span>
                    <Form.Item name="email1" noStyle>
                      <Input placeholder="입력" disabled style={{ width: 180 }} />
                    </Form.Item>
                  </Flex>
                </Form.Item>
                <Form.Item label="휴대폰 번호" required>
                  <Flex columnGap={10}>
                    <Form.Item
                      name="phoneNumber1"
                      noStyle
                      {...phoneConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={3}
                        controls={false}
                      />
                    </Form.Item>
                    -
                    <Form.Item
                      name="phoneNumber2"
                      noStyle
                      {...phoneConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={4}
                        controls={false}
                      />
                    </Form.Item>
                    -
                    <Form.Item
                      name="phoneNumber3"
                      noStyle
                      {...phoneConfig}
                      getValueFromEvent={extractNumbers}
                    >
                      <Input
                        placeholder="입력"
                        style={{ width: 130 }}
                        maxLength={4}
                        controls={false}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Form>
            </div>
            <div className={styles.container}>
              <Form form={passwordForm} {...formItemLayout} onFinish={updatePassword}>
                <Flex justifyContent="space-between">
                  <h2>비밀번호 변경</h2>
                  <Button width={124} onClick={() => passwordForm.submit()}>
                    수정하기
                  </Button>
                </Flex>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="기존 비밀번호"
                      name="existingPassword"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password placeholder="입력" style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="비밀번호"
                      name="password"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password placeholder="입력" style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  justify="space-between"
                  style={{ width: 300, marginTop: -20, marginBottom: 13, marginLeft: 160 }}
                >
                  <Col span={24}>
                    영문, 숫자, 특수문자 3종류 이상을 조합하여 최소 8자리 이상의 길이를
                    입력해주세요.
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="비밀번호 확인"
                      name="passwordConfirm"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const pattern =
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

                            if (!pattern.test(value)) {
                              return Promise.reject(new Error('비밀번호 규칙이 맞지 않습니다.'));
                            }
                            if (!pattern.test(getFieldValue('password'))) {
                              return Promise.reject(new Error('비밀번호 규칙이 맞지 않습니다.'));
                            }

                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(new Error('패스워드 정보가 맞지 않습니다.'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="입력" style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        )}
      </ul>
      <Flex justifyContent="center" style={{ marginTop: 40 }}>
        <Button
          width={124}
          onClick={() => {
            updateProfile();
          }}
        >
          수정하기
        </Button>
      </Flex>
    </div>
  );
}
