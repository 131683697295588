export const checkIsInt = pagePathname => {
  const infos = localStorage.getItem('pageOnboardingCheck');

  if (infos) {
    try {
      const onboardingStatus = JSON.parse(infos);

      if (onboardingStatus && onboardingStatus[pagePathname]) {
        return false;
      }
    } catch (e) {
      console.error(e);
      return true;
    }
  }

  return true;
};

export const recordOnboardingCheck = pagePathname => {
  const infos = localStorage.getItem('pageOnboardingCheck');
  const pathname = pagePathname;

  if (infos) {
    try {
      const responseData = JSON.parse(infos);
      const changeData = {
        ...responseData,
        [pathname]: true,
      };

      localStorage.setItem('pageOnboardingCheck', JSON.stringify(changeData));
    } catch (e) {
      console.error(e);
    }
  } else {
    const newData = {
      [pathname]: true,
    };

    localStorage.setItem('pageOnboardingCheck', JSON.stringify(newData));
  }
};
