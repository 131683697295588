import bannerImage from '../../assets/images/image/minimumWageImage.webp';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Skeleton } from 'antd';
import { Button, MultiButton } from '../button';
import styles from './minimum-wage-banner.module.css';
import { Flex } from '../flex';
import { SearchBox } from '../input';
import { DeleteIcon } from '../icon';
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { callApi, snackBar } from '../../utils';
import { useQuery } from 'react-query';
import { CheckBox } from '../selections';
import { ChipsContainer } from '../selections/chips-container';
import useDebounce from '../../hooks/useDebounce';
import { useVirtualizer } from '@tanstack/react-virtual';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('select', {
    size: 44,
    header: ({ table }) => (
      <CheckBox
        checked={table.getIsAllRowsSelected()}
        onChange={e => table.toggleAllRowsSelected(e.target.checked)}
      />
    ),
    cell: ({ row }) => (
      <CheckBox
        checked={row.getIsSelected()}
        onChange={e => row.toggleSelected(e.target.checked)}
      />
    ),
  }),
  columnHelper.accessor('company_name', {
    size: 506,
    header: () => '사업장명',
    cell: info => info.getValue(),
  }),
];

const SettingMinimumWageModal = props => {
  const { open, handleOk, handleCancel } = props;
  const tableContainerRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [rowSelection, setRowSelection] = useState({});

  const debouncedSearchText = useDebounce(searchText, 100);

  const getAllCompanyList = async () => {
    try {
      const param = {
        opId: sessionStorage.getItem('userId'),
        searchText,
      };

      const { result, data } = await callApi('api/platform/getBasicCompanyList', param);

      if (result) {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  const { data = [], isLoading } = useQuery('changeMinWage', getAllCompanyList);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row?.mb_c_no,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const deleteSelectionData = id => {
    for (const row of table?.getCoreRowModel().rows) {
      if (row.id === id) {
        return row.toggleSelected(false);
      }
    }
  };

  useEffect(() => {
    if (data && data.length > 0 && isLoading === false) {
      table?.toggleAllRowsSelected(true);
    }
  }, [data, isLoading]);

  useEffect(() => {
    table?.getColumn('company_name').setFilterValue(searchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    const selectedRow = table?.getState().rowSelection;
    const changeSelectionData = data.filter(item => selectedRow.hasOwnProperty(item.mb_c_no));

    setRowSelection(changeSelectionData);
  }, [table?.getState().rowSelection]);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 66,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 4,
  });

  const onChangeMinWage = async () => {
    try {
      const param = {
        opId: sessionStorage.getItem('userId'),
        ids: Object.keys(table?.getState().rowSelection),
      };

      const { result } = await callApi('api/platform/updateCompanyMinWage', param);

      if (result) {
        snackBar('2025년 최저임금으로 변경되었어요.');
      } else {
        snackBar('변경에 실패했어요.');
      }
    } catch (e) {
      snackBar('서버에 오류가 발생했어요.');
    } finally {
      handleCancel();
    }
  };

  return (
    <Modal
      title={
        <>
          2025년 최저임금(
          <span className={styles.point_msg} style={{ fontSize: 18 }}>
            10,030원
          </span>
          ) 변경
        </>
      }
      open={open}
      centered
      closeIcon={<DeleteIcon />}
      width={630}
      onOk={handleOk}
      onCancel={handleCancel}
      styles={{
        body: {
          minHeight: 600,
          height: 600,
        },
      }}
      footer={
        <MultiButton>
          <Button type="line-gray" size={'sm'} onClick={handleCancel}>
            닫기
          </Button>
          <Button
            type="primary"
            size={'sm'}
            onClick={onChangeMinWage}
            isDisabled={!(rowSelection && rowSelection.length > 0)}
          >
            2025년 최저임금 변경하기
          </Button>
        </MultiButton>
      }
    >
      <Flex direction={'column'} rowGap={40} alignItems={'flex-start'}>
        <Flex direction={'column'} alignItems={'flex-start'} style={{ width: '100%' }}>
          <Flex
            direction={'column'}
            alignItems={'flex-start'}
            rowGap={16}
            style={{ width: '100%' }}
          >
            <h4 className={styles.modal_title}>
              수임업체 목록 <span>{table?.getCoreRowModel().rows?.length || 0}</span>
            </h4>
            <SearchBox
              placeholder={'입력'}
              style={{ width: '100%' }}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </Flex>
          <div
            className={`${styles.table_container} ${rowSelection && rowSelection.length > 0 ? styles.on_bottom_modal : ''}`}
            ref={tableContainerRef}
          >
            <table className="hulampro_table table_scroll">
              <thead>
                {table.getHeaderGroups().map(headerGroup => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        return (
                          <th key={header.id} style={{ width: `${header.getSize()}px` }}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody
                style={
                  isLoading
                    ? {}
                    : {
                        display: 'grid',
                        height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                        position: 'relative', //needed for absolute positioning of rows
                      }
                }
              >
                {isLoading
                  ? Array.from({ length: 10 })
                      .fill(0)
                      .map((_, index) => (
                        <tr key={`skeleton_idx_${index}`}>
                          <td>
                            <Skeleton.Node
                              active
                              style={{
                                height: 21,
                                width: 21,
                              }}
                            />
                          </td>
                          <td>
                            <Skeleton.Node
                              active
                              style={{
                                height: 21,
                                width: 500,
                              }}
                            />
                          </td>
                        </tr>
                      ))
                  : rowVirtualizer.getVirtualItems().map(virtualRow => {
                      const row = rows[virtualRow.index];

                      return (
                        <tr
                          className={styles.table_row}
                          data-index={virtualRow.index} //needed for dynamic row height measurement
                          ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                          key={row.id}
                          style={{
                            display: 'flex',
                            position: 'absolute',
                            transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                            width: '100%',
                          }}
                          onClick={() => row.toggleSelected(!row.getIsSelected())}
                        >
                          {row.getVisibleCells().map(cell => {
                            return (
                              <td
                                key={cell.id}
                                style={{
                                  display: 'flex',
                                  width: cell.column.getSize(),
                                }}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </Flex>
        {rowSelection && rowSelection.length > 0 && (
          <div className={styles.bottom_modal}>
            <div className={styles.bottom_modal_title}>
              <em>2025년 최저임금으로 변경될 수임업체</em>
              <span>{rowSelection.length}개</span>
            </div>
            <ChipsContainer
              list={rowSelection}
              height={120}
              maxHeight={120}
              id={'mb_c_no'}
              name={'company_name'}
              onDeleteItem={deleteSelectionData}
              onReset={() => table.toggleAllRowsSelected(false)}
            />
          </div>
        )}
      </Flex>
    </Modal>
  );
};

export const MinimumWageBanner = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const { style } = props;

  const onOpenSettingModal = () => {
    setModalOpen(true);
  };

  const onCloseSettingModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div style={{ ...style }} onClick={onOpenSettingModal}>
        <img src={bannerImage} alt={'2025년 최저임금 일괄 섲엉'} width={667} />
      </div>
      {modalOpen && (
        <SettingMinimumWageModal
          open={modalOpen}
          handleCancel={onCloseSettingModal}
        ></SettingMinimumWageModal>
      )}
    </>
  );
};
