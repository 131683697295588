import { Flex, Tab } from 'components/hulam_platform';
import { useEffect, useState } from 'react';
import ProfileEditTab from './tabs/ProfileEditTab';
import { callApi, snackBar } from 'utils';
import InsuranceIntegrationTab from './tabs/InsuranceIntegrationTab';
import { useAuth } from 'AuthProvider';
import { SubscriptStatusLabel } from '../../../components/subscript/subscript-status-label';

export const HulamSetting = () => {
  const [toggleState, setToggleState] = useState({
    toggle1: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [fISubscriptionStatus, setFISubscriptionStatus] = useState(null);

  const { author } = useAuth();

  const setToggle = (target, bool) => setToggleState({ ...toggleState, [target]: bool });

  const getSubscribeInfo = async () => {
    const param = {
      opId: sessionStorage.getItem('userId'),
    };

    const { result, msg, data } = await callApi('api/platform/billing/getSubscribe', param);

    if (result) {
      if (msg !== '구독 데이터가 없음') {
        setSubscriptionStatus(true);

        if (data) {
          const isFISubscription = data.find(item => item.product_name === '4대보험료 조회');
          setFISubscriptionStatus(isFISubscription !== undefined);
        }
      } else {
        setSubscriptionStatus(false);
        setFISubscriptionStatus(false);
      }
    } else {
      setSubscriptionStatus(false);
      setFISubscriptionStatus(false);
    }
  };

  /**@function updateProfile()
   * 프로필 수정
   */
  const updateProfile = () => {
    snackBar('프로필 정보가 수정되었습니다.');
  };

  const menuTitle = author === 'manager' ? '환경 설정' : '계정 설정';

  useEffect(() => {
    getSubscribeInfo();
  }, []);

  const tabItems = () => {
    if (author === 'manager') {
      return [
        {
          key: '1',
          label: '4대 보험 연동',
          children: (
            <InsuranceIntegrationTab
              toggleState={toggleState}
              setToggle={setToggle}
              subscriptionStatus={subscriptionStatus}
              fISubscriptionStatus={fISubscriptionStatus}
            />
          ),
          forceRender: true,
        },
        {
          key: '2',
          label: '프로필 수정',
          children: <ProfileEditTab confirm={updateProfile} />,
          forceRender: true,
        },
      ];
    } else {
      return [
        {
          key: '1',
          label: '프로필 수정',
          children: <ProfileEditTab confirm={updateProfile} />,
          forceRender: true,
        },
      ];
    }
  };

  return (
    <div>
      <Flex justifyContent={'space-between'}>
        <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>{menuTitle}</h2>
        {subscriptionStatus === false && <SubscriptStatusLabel />}
      </Flex>
      <Tab items={tabItems()} style={{ marginTop: 40 }} />
    </div>
  );
};
