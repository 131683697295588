export const onBoardingInfo = {
  '/hulamHome': {
    title: '휴램프로 사용 가이드',
    description:
      '4대 보험 연동, 직원 정보 등록, 수임 업체별 담당 직원을 설정하는 방법을 영상으로 확인해보세요.',
    videoId: 'SVpSu8Yvgbk',
    thumbnailUrl: 'https://img.youtube.com/vi/SVpSu8Yvgbk/sddefault.jpg',
  },
  '/hulamEmployee': {
    title: '직원 등록 방법',
    description: '수임 업체를 담당할 직원을 등록하고 정보를 수정할 수 있어요.',
    videoId: 'oUKH0x24Z10',
    thumbnailUrl: 'https://img.youtube.com/vi/oUKH0x24Z10/sddefault.jpg',
  },
  '/hulamSetting': {
    title: '4대 보험 연동 방법',
    description: '공인인증서로 4대보험 연동 시 자동으로 수임 업체 목록을 불러올 수 있어요.',
    videoId: 'NgAP9hnmIws',
    thumbnailUrl: 'https://img.youtube.com/vi/NgAP9hnmIws/sddefault.jpg',
  },
};

//유튜브 섬네일 가져오기
//https://img.youtube.com/vi/oUKH0x24Z10/sddefault.jpg
